import { createRouter, createWebHistory } from 'vue-router';
import MainScreen from '../views/MainScreen.vue';
import HeaderScreen from '../views/HeaderScreen.vue';
import FooterScreen from '../views/FooterScreen.vue';

const routes = [
  {
    path: '/',
    component: MainScreen,
  },
  {
    path: '/header',
    component: HeaderScreen,
  },
  {
    path: '/footer',
    component: FooterScreen,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
