<!-- eslint-disable vuejs-accessibility/no-distracting-elements -->
<template>
  <footer class="main-footer">
    <marquee
    v-if="newsFeedString  "
    class="main-footer__feed"
    direction="left"
    scrollamount="8"
    >
      {{ newsFeedString }}
    </marquee>
  </footer>
</template>

<script>
import axios from 'axios';
import { XMLParser } from 'fast-xml-parser';
import dayjs from 'dayjs';
import difference from 'lodash/difference';

export default {
  name: 'MainFooter',
  async created() {
    try {
      this.updateNews();

      this.newsIntervalId = setInterval(async () => {
        this.updateNews();
      }, 1000 * 60 * 60);
    } catch (err) {
      console.error(err);
    }
  },
  beforeUnmount() {
    clearInterval(this.newsIntervalId);
  },
  methods: {
    async updateNews() {
      try {
        const newsXML = (await axios.get('https://cors-anywhere.herokuapp.com/https://www.vedomosti.ru/rss/news.xml')).data;

        const parser = new XMLParser();
        const newsItemList = parser.parse(newsXML).rss.channel.item;

        const valueOfCurrentDate = dayjs().set('hour', 0).set('minute', 0).set('second', 0)
          .valueOf();

        const filteredNews = newsItemList
          .filter(({ pubDate }) => dayjs(pubDate).valueOf() >= valueOfCurrentDate)
          .map(({ title }) => title);

        const newNews = difference(filteredNews, this.rssNewsList);

        this.rssNewsList.push(...newNews);

        if (newNews.length && this.rssNewsList.length > newNews.length) {
          this.rssNewsList.splice(0, newNews.length);
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  computed: {
    newsFeedString() {
      return this.rssNewsList.join('.     ').toUpperCase();
    },
  },
  data() {
    return {
      rssNewsList: [],
      newsIntervalId: null,
    };
  },

};
</script>

<style lang="scss">
.main-footer {
  display: flex;
  align-items: center;
  height: 10vh;
  padding: 30px;

  background-color: rgba(255, 255, 255, 0.38);
  backdrop-filter: blur(20px);
}

.main-footer__feed {
  padding: 10px 50px;
  color: #292929;
  font-size: 18px;
  font-weight: 500;
  white-space: pre;

  border-radius: 30px;
  background-color:rgba(255, 255, 255, 0.3);
}

</style>
