<template>
  <div class="main-content">
    <iframe
    title="s"
      class="main-content__frame"
      :src="contentUrl"
    />
  </div>
</template>

<script>
export default {
  name: 'MainContent',
  created() {
    if (!this.$route.query.content) {
      this.$router.replace({
        query: {
          content: 'https://weather.srly.io/?lat=37.77&lng=-122.43',
        },
      });
    }
  },
  data() {
    return {
      contentUrl: null,
    };
  },
  watch: {
    '$route.query': {
      handler(value) {
        this.contentUrl = value.content ? value.content : null;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
  .main-content {
    flex-grow: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    // background-color: rgb(21, 214, 12);

  }

  .main-content__frame {
    width: 90%;
    height: 90%;

    border: 0;
  }
</style>
