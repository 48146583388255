<template>
  <div class="main-screen">
    <main-header />

    <main-content />

    <main-footer />
  </div>
</template>

<script>
import MainContent from '../components/MainContent.vue';
import MainFooter from '../components/MainFooter.vue';
import MainHeader from '../components/MainHeader.vue';

export default {
  components: { MainHeader, MainFooter, MainContent },

};
</script>

<style lang="scss">
  .main-screen {
    display: flex;
    flex-flow: column nowrap;

    height: 100vh;

    background: linear-gradient(-225deg, #22E1FF 0%, #1D8FE1 48%, #625EB1 100%);
  }
</style>
