<template>
  <div class="current-time">
    <span class="current-time__time">{{ currentTime }}</span>
    <span>{{ currentDate }}</span>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

export default {
  name: 'CurrentTime',
  data() {
    return {
      timerId: null,
      currentTime: null,
      currentDate: null,
    };
  },
  created() {
    this.clockStart();
  },
  beforeUnmount() {
    this.clockStop();
  },
  methods: {
    update() {
      const date = new Date();

      let hours = date.getHours();
      if (hours < 10) hours = `0${hours}`;

      let minutes = date.getMinutes();
      if (minutes < 10) minutes = `0${minutes}`;

      let seconds = date.getSeconds();
      if (seconds < 10) seconds = `0${seconds}`;
      this.currentTime = `${hours}:${minutes}`;
      this.currentDate = dayjs().locale('ru').format('dd, DD MMMM');
    },

    clockStart() {
      if (!this.timerId) {
        this.timerId = setInterval(this.update, 1000);
      }
      this.update(); // <--  начать тут же, не ждать 1 секунду пока первый setInterval сработает
    },

    clockStop() {
      clearInterval(this.timerId);
      this.timerId = null;
    },
  },

};
</script>

<style lang="scss">
  .current-time {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
  }

  .current-time__time {
    font-weight: 700;
    font-size: 22px;
  }
</style>
