<template>
  <div class="current-weather" v-if="weather">
    <p class="current-weather__text">Сейчас в <span class="current-weather__city">Москве</span></p>
    <img class="current-weather__icon" :src="weatherIconUrl" alt="weather icon">
    <span class="current-weather__temperature">{{ temperature }} °C</span>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CurrentWeather',
  async created() {
    this.weather = await this.getWeather();
    this.intervalId = setInterval(async () => {
      this.weather = await this.getWeather();
    }, 200000);
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    async getWeather() {
      const weather = await axios.get('https://api.openweathermap.org/data/2.5/weather', {
        params: {
          lat: this.coordinates.lat,
          lon: this.coordinates.lon,
          appid: 'a95832ab72115b7642051c0304864290',
          units: 'metric',
          lang: 'ru',
        },
      });

      return weather.data;
    },
  },
  computed: {
    temperature() {
      const temp = Math.round(this.weather?.main?.temp);
      return temp > 0 ? `+${temp}` : temp;
    },
    weatherIconUrl() {
      const icon = this.weather?.weather?.[0]?.icon;
      return `https://openweathermap.org/img/wn/${icon}@2x.png`;
    },
  },
  data() {
    return {
      weather: null,
      coordinates: {
        lat: 55.7522,
        lon: 37.6156,
      },

      intervalId: null,
    };
  },
};
</script>

<style lang="scss">
  .current-weather {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .current-weather__text {
    margin: 0;
    font-size: 18px;
  }

  .current-weather__city {
    font-weight: 500;
  }

  .current-weather__icon {
    width: 40px;
  }

  .current-weather__temperature {
    font-size: 22px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

</style>
