<template>
    <div class="header-screen">
        <main-header class="header-screen__content"/>
    </div>
</template>

<script>
import MainHeader from '../components/MainHeader.vue';

export default {
  name: 'HeaderScreen',
  components: {
    MainHeader,
  },

};
</script>

<style lang="scss">
  @use '../styles/common';

  .header-screen {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100vh;

    @extend %main-background;
  }

  .header-screen__content {
    flex-grow: 1;

    @extend %background-underlay;
  }
</style>
