<template>
  <header class="main-header">
    <div class="main-header__name">
      {{ schoolName }}
    </div>
    <div class="main-header__info">
      <current-weather class="main-header__weather"/>
      <current-time class="main-header__time" />
    </div>
  </header>
</template>

<script>
import CurrentTime from './CurrentTime.vue';
import CurrentWeather from './CurrentWeather.vue';

export default {
  name: 'MainHeader',
  components: { CurrentTime, CurrentWeather },
  data() {
    return {
      schoolName: 'Playschool',
    };
  },
};
</script>

<style lang="scss">
  .main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;

    background-color: rgba(255,255,255,.38);
    backdrop-filter: blur(20px);

    @media screen and (max-width: 768px) {
      padding:  16px;
    }
  }

  .main-header__name {
    font-size: 25px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
  }

  .main-header__info {
    display: flex
  }

  .main-header__weather {
    margin-right: 30px;

    @media screen and (max-width: 768px) {
      margin-right: 16px;
    }
  }

  .main-header__time {
    font-size: 20px;
  }

</style>
