<template>
  <div class="footer-screen">
    <main-footer class="footer-screen__content"/>
  </div>
</template>

<script>
import MainFooter from '../components/MainFooter.vue';

export default {
  components: {
    MainFooter,
  },

};
</script>

<style lang="scss">
  @use '../styles/common';

  .footer-screen {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100vh;

    background: linear-gradient(50deg, #22E1FF 0%, #1D8FE1 48%, #625EB1 100%);
  }

  .footer-screen__content {
    flex-grow: 1;

    @extend %background-underlay;
  }
</style>
